import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { map,mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ColorSet } from '@amcharts/amcharts4/core';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // private apiBaseUrl = 'http://localhost:3000/api';  // URL to web api
  // private apiBaseUrl = 'http://127.0.0.1:3000';  // URL to web api
  // private apiBaseUrl = 'https://ngnodesrv.azurewebsites.net';  // URL to web api
  private apiBaseUrl = environment.apiUrl;  // URL to web api

  constructor(private http: HttpClient) {

  }

  loginService(username,password,LoginDateTime): Observable<any> {

    //const headers = { 'Content-Type': 'application/json'}
    const body = { username: username, password:password , LoginDateTime:LoginDateTime,AppsCode:1}

    return this.http.post<any>(this.apiBaseUrl+'/front/authService/loginWB',body).pipe(
      map(result => {
        if(result.token){

          localStorage.setItem('access_token', result.token);

          // if(result.UserId){ localStorage.setItem('UserId', result.UserId); }
          // if(result.EMailId){ localStorage.setItem('EMailId', result.EMailId); }
          // if(result.CompanyName){ localStorage.setItem('CompanyName', result.CompanyName); }
          // if(result.Cat){localStorage.setItem('Cat',result.Cat);}
          // if(result.FullName){localStorage.setItem('FullName',result.FullName);}
          // if(result.SPCODE){localStorage.setItem('SPCODE',result.SPCODE);}
          // if(result.SPEmailId){localStorage.setItem('SPEmailId',result.SPEmailId);}
          // if(result.SecondaryEmailId){localStorage.setItem('SecondaryEmailId',result.SecondaryEmailId);}
          // if(result.SPName){localStorage.setItem('SPName',result.SPName);}

          return result;
        }else{
          return result;
        }
      })
    );
  }

  signup(postData): Observable<any> {
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/signup',postData)
  }

  onforgotPwdFormubmit(postData): Observable<any> {
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/onforgotPwdFormubmit',postData)
  }


  existUsername(postData): Observable<any> {
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/existUsername',postData);
  }


  uploadImages(postData): Observable<any> {
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/fileUpload',postData);
  }

  getKamPersons(): Observable<any> {
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/getKamPersons',{});
  }

  getCountry(): Observable<any> {
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/getCountry',{});
  }

  getState(postData): Observable<any> {
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/getState',postData);
  }

  getCity(postData): Observable<any> {
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/getCity',postData);
  }

  // get_ip():Observable<any>{
  //   return this.http.get<any>("https://api.ipify.org/?format=json")
  // }

  GetBannerDetail(postData:any):Observable<any>{
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/BannerDetail',postData);
  }

  FillAllMastersMix(postData:any):Observable<any>{
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/FillAllMastersMix',postData);
  }

  GetOTPWeb(postData:any):Observable<any>{
    return this.http.post<any>("https://api.crm.narolagems.com:9907/AuthOTP/GetOTPWeb",postData);
  }

  WBBiodataFill(postData:any):Observable<any>{
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/WBBiodataFill',postData);
  }

  VerifyMail(postData:any):Observable<any>{
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/VerifyMail',postData);
  }

  DocumentMail(postData:any):Observable<any>{
    return this.http.post<any>(this.apiBaseUrl+'/front/authService/DocumentMail',postData);
  }
}
