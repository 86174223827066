import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public router: Router) {

  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let newHeaders = req.headers;


    if(req.url == 'https://api.ipify.org/?format=json'){
      const authReq = req.clone({headers: newHeaders});
      return next.handle(authReq);
    }else{

      if(req.url.search("fileUpload")>=0){
      }else{
        newHeaders = newHeaders.append('Content-Type', 'application/json');
      }


      const AccTkn = localStorage.getItem("access_token") ? "Bearer " + localStorage.getItem("access_token") : "Bearer ";


      newHeaders = newHeaders.append('Authorization', AccTkn);
      const authReq = req.clone({headers: newHeaders});
      //return next.handle(authReq);


      return next.handle(authReq).pipe( tap(() => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }else{
              localStorage.clear();
              window.location.href = window.location.origin;
            }

          }
        }));

    }
  }
}

