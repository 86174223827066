import {BrowserModule} from "@angular/platform-browser";
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {JwtModule} from "@auth0/angular-jwt";
import {AuthGuard} from "./front/services/auth.guard";
import {AuthService} from "./front/services/auth.service";
import {TokenInterceptor} from "./front/services/token-interceptor";
import {DatePipe} from "@angular/common";
// import {NgwWowModule} from "ngx-wow";
import {AppComponent} from "./app.component";



import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MaterialModule} from "./material/material.module";
import {AppRoutingModule} from "./app-routing.module";

import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';


import {EditorModule} from "@tinymce/tinymce-angular";
// import { TwoDigitDecimaNumberDirective } from '../app/common/two-digit-decima-number.directive';


// import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { RouterModule, UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './lowerCaseUrlSerializer';

import { ToastrModule } from 'ngx-toastr';

// import { SafePipe } from './Helpers/safe.pipe';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}


// NgwWowModule,

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    EditorModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    AgGridModule,
  ],
  providers: [DatePipe, AuthGuard, AuthService, { provide:UrlSerializer, useClass:LowerCaseUrlSerializer },{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule {

}

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

// export function HttpLoaderFactory(http: HttpClient) {
//   // return new TranslateHttpLoader(http, '../../../assets/i18n/', '.json');
//   return new TranslateHttpLoader(http, 'https://apichn.narola.in/front/getlanguages?language=',''); // When live uncomment this line
//   // return new TranslateHttpLoader(http, 'http://localhost:3000/front/getlanguages?language=','');
// }
