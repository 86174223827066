import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { AuthGuard } from './front/services/auth.guard';

const routes: Routes = [


  { path: '', loadChildren: () => import('./front/home-module/home-module.module').then(m => m.HomeModuleModule) },
  { path: 'login', loadChildren: () => import('./front/home-module/home-module.module').then(m => m.HomeModuleModule) },
  { path: 'registration/:pagetype', loadChildren: () => import('./front/home-module/home-module.module').then(m => m.HomeModuleModule) },
  { path: 'userpanel', loadChildren: () => import('./front/userpanel-module/userpanel-module.module').then(m => m.UserpanelModuleModule), canActivate: [AuthGuard] },
  { path: 'ndmp', loadChildren: () => import('./front/ndmpbid-module/ndmpbid-module.module').then(m => m.NDMPBidModuleModule), canActivate: [AuthGuard] },
  { path: 'npd', loadChildren: () => import('./front/npdbid-module/npdbid-module.module').then(m => m.NPDBidModuleModule) ,canActivate:[AuthGuard] },
  { path: 'bio/:id', loadChildren: () => import('./front/bio-module/bio-module.module').then(m => m.BioModuleModule) },
  { path: 'appoinment', loadChildren: () => import('./front/appoinment-module/appoinment-module.module').then(m => m.AppoinmentModuleModule), canActivate:[AuthGuard] },
  { path: 'comparestones', loadChildren: () => import('./front/compare-stones-module/compare-stones-module.module').then(m => m.CompareStonesModuleModule) },
  { path: 'myprofile', loadChildren: () => import('./front/profile-module/profile-module.module').then(m => m.ProfileModuleModule) ,canActivate:[AuthGuard] },
  { path: 'notify', loadChildren: () => import('./front/notify-master-module/notify-master-module.module').then(m => m.NotifyMasterModuleModule) ,canActivate:[AuthGuard] },
  { path: 'parcelgoods', loadChildren: () => import('./front/parcel-goods-module/parcel-goods-module.module').then(m => m.ParcelGoodsModuleModule) },
  { path: 'stonedetail/:pid', loadChildren: () => import('./front/stone-details-module/stone-details-module.module').then(m => m.StoneDetailsModuleModule)  },
  { path: 'unveling', loadChildren: () => import('./front/unveling-module/unveling-module.module').then(m => m.UnvelingModuleModule) },
  { path: 'upcomingstone', loadChildren: () => import('./front/upcoming-stone-module/upcoming-stone-module.module').then(m => m.UpcomingStoneModuleModule), canActivate:[AuthGuard] },
  { path: 'searchdiamond/:pagename', loadChildren: () => import('./front/search-diamond-module/search-diamond-module.module').then(m => m.SearchDiamondModuleModule), canActivate: [AuthGuard]   },
  { path: 'basketstone', loadChildren: () => import('./admin/admin-basket-module/admin-basket-module.module').then(m => m.AdminBasketModuleModule) ,canActivate:[AuthGuard] },
  { path: 'adminndmp', loadChildren: () => import('./admin/admin-ndmpmodule/admin-ndmpmodule.module').then(m => m.AdminNDMPModuleModule) ,canActivate:[AuthGuard] },
  { path: 'adminnpd', loadChildren: () => import('./admin/admin-ndpmodule/admin-ndpmodule.module').then(m => m.AdminNDPModuleModule) ,canActivate:[AuthGuard] },
  { path: 'bidsummary', loadChildren: () => import('./admin/admin-npdsummary-module/admin-npdsummary-module.module').then(m => m.AdminNPDSummaryModuleModule) ,canActivate:[AuthGuard] },
  { path: 'adminstockview', loadChildren: () => import('./admin/admin-stock-view-module/admin-stock-view-module.module').then(m => m.AdminStockViewModuleModule), canActivate: [AuthGuard]  },
  { path: 'adminviewrequest' ,  loadChildren: () => import('./admin/admin-view-request-module/admin-view-request-module.module').then(m => m.AdminViewRequestModuleModule) ,canActivate:[AuthGuard] },
  { path: 'wishstone', loadChildren: () => import('./admin/admin-wish-list-module/admin-wish-list-module.module').then(m => m.AdminWishListModuleModule) ,canActivate:[AuthGuard] },
  { path: 'adminpanel', loadChildren: () => import('./admin/admin-panel-module/admin-panel-module.module').then(m => m.AdminPanelModuleModule), canActivate: [AuthGuard]  },
  { path: 'bannermast', loadChildren: () => import('./admin/banner-module/banner-module.module').then(m => m.BannerModuleModule) ,canActivate:[AuthGuard] },
  { path: 'confirmstone', loadChildren: () => import('./admin/confirm-stone-module/confirm-stone-module.module').then(m => m.ConfirmStoneModuleModule) , canActivate: [AuthGuard]  },
  { path: 'customerbuyingstages', loadChildren: () => import('./admin/customer-buying-stages-module/customer-buying-stages-module.module').then(m => m.CustomerBuyingStagesModuleModule),canActivate:[AuthGuard] },
  { path: 'diaminventory', loadChildren: () => import('./admin/diam-inventory-module/diam-inventory-module.module').then(m => m.DiamInventoryModuleModule) , canActivate: [AuthGuard]  },
  { path: 'discountmast', loadChildren: () => import('./admin/discount-mast-module/discount-mast-module.module').then(m => m.DiscountMastModuleModule) , canActivate: [AuthGuard]  },
  { path: 'messagemst', loadChildren: () => import('./admin/message-mast-module/message-mast-module.module').then(m => m.MessageMastModuleModule) ,canActivate:[AuthGuard]},
  { path: 'mixproductmaster', loadChildren: () => import('./admin/mix-product-module/mix-product-module.module').then(m => m.MixProductModuleModule) ,canActivate:[AuthGuard] },
  { path: 'ndmpwebtrack', loadChildren: () => import('./admin/ndmpweb-track-module/ndmpweb-track-module.module').then(m => m.NDMPWebTrackModuleModule) ,canActivate:[AuthGuard]},
  { path: 'notification', loadChildren: () => import('./admin/notification-mast-module/notification-mast-module.module').then(m => m.NotificationMastModuleModule) ,canActivate:[AuthGuard]},
  { path: 'notificationtypemaster', loadChildren: () => import('./admin/notification-type-master-module/notification-type-master-module.module').then(m => m.NotificationTypeMasterModuleModule) ,canActivate:[AuthGuard] },
  { path: 'orderprocess', loadChildren: () => import('./admin/order-process-module/order-process-module.module').then(m => m.OrderProcessModuleModule) , canActivate: [AuthGuard]  },
  { path: 'purchasemaster', loadChildren: () => import('./admin/pur-mode-mast-module/pur-mode-mast-module.module').then(m => m.PurModeMastModuleModule) ,canActivate:[AuthGuard] },
  { path: 'stockshare', loadChildren: () => import('./admin/stock-share-module/stock-share-module.module').then(m => m.StockShareModuleModule), canActivate: [AuthGuard]  },
  { path: 'useractivation', loadChildren: () => import('./admin/user-activation-module/user-activation-module.module').then(m => m.UserActivationModuleModule) , canActivate: [AuthGuard]  },
  { path: 'webtrack', loadChildren: () => import('./admin/web-track-module/web-track-module.module').then(m => m.WebTrackModuleModule) , canActivate: [AuthGuard]  },
  
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
