import {Component} from '@angular/core';
// import {NgwWowService} from 'ngx-wow';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'narolagems';

  onActivate(e) {
    //console.log('hash');
  }

  // private wowService: NgwWowService
  constructor() {
    // this.wowService.init();
  }

}
