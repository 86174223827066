import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { truncateWithEllipsis } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable()
export class AuthGuard  {
  constructor(private router: Router) { }

  helper = new JwtHelperService();
  token = localStorage.getItem('access_token');
  decodedToken: any;
  expirationDate: Date;
  isExpired:boolean;

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      if(localStorage.getItem('access_token')!=null){
        this.decodedToken = this.helper.decodeToken(this.token);
        this.expirationDate = this.helper.getTokenExpirationDate(this.token);
        this.isExpired = this.helper.isTokenExpired(this.token);

        if(state.url == "/adminpanel" && this.decodedToken.Cat == "U"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/useractivation" && this.decodedToken.Cat == "U"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/discountmast" && this.decodedToken.Cat == "U"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }








        if(state.url == "/messagemst" && this.decodedToken.Cat == "U"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }
        if(state.url == "/confirmstone" && this.decodedToken.Cat == "U"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }
        if(state.url == "/adminstockview" && this.decodedToken.Cat == "U"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }
        // if(state.url == "/diaminventory" && this.decodedToken.Cat == "U"){
        //   window.location.href = window.location.origin+'/userpanel';
        //   return true;
        // }
        if(state.url == "/webtrack" && this.decodedToken.Cat == "U"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/searchdiamond/search" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/searchdiamond/mybasket" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/searchdiamond/myorder" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/searchdiamond/mywishlist" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/myprofile" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/discountmast" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/useractivation" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/newarrival" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/adminpanel" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/orderprocess" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/adminstockview" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/confirmstone" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/webtrack" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/messagemst" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/diaminventory" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/stockshare" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/myviewrequest" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/bannermast" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/adminviewrequest" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/ndmpwebtrack" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/ndmp" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/adminndmp" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/notification" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/adminnpd" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/customerbuyingstages" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/appoinment" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/upcomingstone" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }

        if(state.url == "/bidsummary" && this.decodedToken.Cat == "B"){
          window.location.href = window.location.origin+'/userpanel';
          return true;
        }








       return true;
      }else{
        if(state.url == "/terms"){
          return true;
        }
      }

    // console.log('not set param');
    window.location.href = window.location.origin;
    //this.router.navigate(['/']);
    return false;
  }
}
